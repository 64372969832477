import React, { Component } from "react";

export class Header extends Component {
  render() {
    return (
      <div className="header sticky top-0 z-20">
        <header className="body-font bg-purple-main text-gray-600">
          <div className="bg-[#dd5b87] py-2">
            <div className="container mx-auto">
              {/* <a className="title-font flex font-medium text-gray-900">
                <img className="hidden h-10 w-10 sm:block md:ml-0 lg:h-14 lg:w-14" src="https://secureservercdn.net/198.71.233.86/cb6.757.myftpupload.com/wp-content/uploads/2021/12/HDAO-60x60-72dpi-1.svg?time=1651670863"></img>
              </a> */}
              <nav className="mx-auto hidden items-center text-base sm:block">
                <a className="inline-flex items-center rounded-lg text-base font-bold text-white hover:bg-purple-box/80 focus:outline-none sm:mt-2 sm:py-2 sm:px-2 sm:text-sm md:mx-5 md:mt-0 lg:mt-2 lg:py-2 lg:px-3" rel="noreferrer" href="#about">
                  About
                </a>
                <a
                  className="inline-flex items-center rounded-lg text-base font-bold text-white hover:bg-purple-box/80 focus:outline-none sm:mt-2 sm:py-2 sm:px-2 sm:text-sm md:mx-5 md:mt-0 lg:mt-2 lg:py-2 lg:px-3"
                  rel="noreferrer"
                  href="#assistants"
                >
                  Assistants
                </a>
                <a
                  className="inline-flex items-center rounded-lg text-base font-bold text-white hover:bg-purple-box/80 focus:outline-none sm:mt-2 sm:py-2 sm:px-2 sm:text-sm md:mx-5 md:mt-0 lg:mt-2 lg:py-2 lg:px-3"
                  rel="noreferrer"
                  href="#tokenomics"
                >
                  Tokenomics
                </a>
                <a className="inline-flex items-center rounded-lg text-base font-bold text-white hover:bg-purple-box/80 focus:outline-none sm:mt-2 sm:py-2 sm:px-2 sm:text-sm md:mx-5 md:mt-0 lg:mt-2 lg:py-2 lg:px-3" rel="noreferrer" href="#faqs">
                  FAQs
                </a>
                <a
                  className="inline-flex items-center rounded-lg text-base font-bold text-white hover:bg-purple-box/80 focus:outline-none sm:mt-2 sm:py-2 sm:px-2 sm:text-sm md:mx-5 md:mt-0"
                  target="_blank"
                  rel="noreferrer"
                  href="https://forms.gle/D96hyhNDh9DDJAXa8"
                >
                  Join Waitlist
                </a>
                <a
                  className="inline-flex items-center rounded-lg text-base font-bold text-white hover:bg-purple-box/80 focus:outline-none sm:mt-2 sm:py-2 sm:px-2 sm:text-sm md:mx-5 md:mt-0"
                  target="_blank"
                  rel="noreferrer"
                  href="https://humandao.org"
                >
                  Home
                </a>
              </nav>
              <nav className="grid grid-rows-2 items-center text-base sm:hidden">
                <div className="mmx-auto">
                  <a className="mx-3 inline-flex items-center rounded-lg text-base font-bold text-white hover:bg-purple-box/80 focus:outline-none sm:mt-2 sm:py-2 sm:px-2 sm:text-sm md:mt-0 lg:mt-2 lg:py-2 lg:px-3" rel="noreferrer" href="#about">
                    About
                  </a>
                  <a
                    className="mx-auto inline-flex items-center rounded-lg px-3 text-base font-bold text-white hover:bg-purple-box/80 focus:outline-none sm:mt-2 sm:py-2 sm:px-2 sm:text-sm md:mt-0 lg:mt-2 lg:py-2 lg:px-3"
                    rel="noreferrer"
                    href="#assistants"
                  >
                    Assistants
                  </a>
                  <a className="mx-2 inline-flex items-center rounded-lg text-base font-bold text-white hover:bg-purple-box/80 focus:outline-none sm:mt-2 sm:py-2 sm:px-2 sm:text-sm md:mt-0 lg:mt-2 lg:py-2 lg:px-3" rel="noreferrer" href="#tokenomics">
                    Tokenomics
                  </a>
                </div>
                <div className="mx-auto">
                  <a className="mx-4 inline-flex items-center rounded-lg text-base font-bold text-white hover:bg-purple-box/80 focus:outline-none sm:mt-2 sm:py-2 sm:px-2 sm:text-sm md:mt-0 lg:mt-2 lg:py-2 lg:px-3" rel="noreferrer" href="#faqs">
                    FAQs
                  </a>
                  <a
                    className="mx-4 inline-flex items-center rounded-lg text-base font-bold text-white hover:bg-purple-box/80 focus:outline-none sm:mt-2 sm:py-2 sm:px-2 sm:text-sm md:mt-0"
                    target="_blank"
                    rel="noreferrer"
                    href="https://forms.gle/D96hyhNDh9DDJAXa8"
                  >
                    Join Waitlist
                  </a>
                  <a
                    className="mx-4 inline-flex items-center rounded-lg text-base font-bold text-white hover:bg-purple-box/80 focus:outline-none sm:mt-2 sm:py-2 sm:px-2 sm:text-sm md:mt-0"
                    target="_blank"
                    rel="noreferrer"
                    href="https://humandao.org"
                  >
                    Home
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Header;
