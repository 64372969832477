import React, { Component } from "react";
import Accordion from "./accordion";
import Slider from "react-slick";

export class Content extends Component {
  render() {
    const settings = {
      infinite: true,
      autoplay: true,
      arrows: false,
      speed: 1200,
      autoplaySpeed: 3000,
      fade: true,
    };

    return (
      <div>
        <section className="main bg-white">
          <div className="mx-auto">
            <img className="mx-auto block h-20 w-20 pt-4 sm:h-24 sm:w-24  lg:h-28 lg:w-28" src="https://secureservercdn.net/198.71.233.86/cb6.757.myftpupload.com/wp-content/uploads/2021/12/HDAO-60x60-72dpi-1.svg?time=1651670863"></img>
            <h2 style={{ textShadow: "2px 1px white" }} className="bar title-font mx-auto text-center font-play text-sm uppercase tracking-widest text-black md:text-sm lg:w-full lg:text-lg tabletlandscape:w-full">
              Providing wages to +1k families & 3k meals for those in need
            </h2>
          </div>
        </section>
        <section className="hero body-font bg-purple-main">
          <div className="container mx-auto flex flex-col items-center px-5 py-8 md:flex-row lg:px-5 lg:pb-28">
            <div className="-mx-4 mt-auto mb-auto flex flex-wrap content-start md:w-5/6  lg:w-1/2">
              <div className="mb-6 w-full sm:p-4 md:mt-16  lg:pl-8 lg:text-left">
                <h1 style={{ textShadow: "1px 1px black" }} className="title-font mx-auto mb-2 text-center font-play text-4xl uppercase text-purple-content md:text-left md:text-5xl lg:pr-4 lg:text-5xl">
                  PIONEERING A WEB3 GIG ECONOMY
                </h1>
                <p className="mt-6 px-4 text-justify text-lg font-normal  text-black sm:px-0 md:pr-4  md:text-left lg:text-left lg:text-xl">
                  Time is money, and there never seems to be enough of either. Pocket Assistant (PA) NFT is a new solution that can help save your time-wasting woes. This revolutionary service provides holders of PANFT access to a pool of talented
                  assistants from underserved communities ready to do the tasks you don't have time to do.<b> Pocket Assistant NFTs Launching July</b>
                </p>
              </div>
            </div>
            <div className="mx-auto w-5/6 md:mt-3 md:w-2/4 md:max-w-lg md:p-2 lg:mt-0 lg:w-full lg:max-w-lg">
              <Slider {...settings}>
                <div className="mx-auto w-5/6 md:mt-12 md:w-2/3 md:p-2 md:pt-8 lg:mt-2 lg:w-full">
                  <img className="h-full w-full object-contain object-center" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1652719326/hdao-nft/12_vnujwh.png" alt="person multi-tasking"></img>
                </div>
                <div className="mx-auto w-5/6 md:mt-12 md:w-2/3 md:p-2 md:pt-8 lg:mt-16">
                  <img className="h-full w-full object-contain object-center" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1651672451/hdao-pa/11_ufniuj.png" alt="person arranging folder"></img>
                </div>
                <div className="mx-auto w-5/6 md:mt-12 md:w-2/3 md:p-2 md:pt-8 lg:mt-12">
                  <img className="h-full w-full object-contain object-center" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1655406936/hdao-nft/33_jolrmo.png" alt="groupwork"></img>
                </div>
              </Slider>{" "}
            </div>
          </div>
        </section>
        <section>
          <div className="w-1/2"></div>
        </section>
        <section id="about" className="main-feature body-font bg-[#9de0fa]">
          <div className="container mx-auto px-5 pt-16 pb-20 lg:pb-0">
            <div className="mb-20 flex w-full flex-col text-center">
              <h1 style={{ textShadow: "1px 1px white" }} className="mx-4 mt-20 font-play text-4xl uppercase text-black lg:text-5xl">
                A new way to get things done
              </h1>
              <p className="mx-auto mt-4 text-xl leading-relaxed lg:w-2/3">
                NFT is access key to the app. <br></br>No personal info required.<br></br>NFT-as-a-service.
              </p>
            </div>
            <div className="mx-auto -mt-12 w-60 sm:hidden lg:hidden">
              <div className="rounded-lg px-4 pb-6">
                <img src="https://res.cloudinary.com/daljbo1q0/image/upload/v1652688154/hdao-nft/13_ytevpe.png"></img>
              </div>
            </div>
            <div>
              <img className="hidden md:mx-auto  md:-mb-20 md:block lg:mb-0 lg:block lg:w-full" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1653651473/hdao-nft/test_ghk9cf.png"></img>
            </div>
            {/* <div className="-m-4 flex flex-wrap text-center md:hidden lg:hidden">
              <div className="w-full p-4 sm:w-1/2 md:w-1/4">
                <div className="rounded-lg px-4 py-6">
                  <img src="https://res.cloudinary.com/daljbo1q0/image/upload/v1652625194/hdao-nft/14_elgywm.png"></img>
                  <h2 className="title-font text-2xl font-medium text-gray-900">One-time upfront cost, no recurring fees</h2>
                </div>
              </div>
              <div className="w-full p-4 sm:w-1/2 md:w-1/4">
                <div className="rounded-lg px-4 py-6">
                  <img src="https://res.cloudinary.com/daljbo1q0/image/upload/v1652625194/hdao-nft/15_fazy0b.png"></img>
                  <h2 className="title-font text-2xl font-medium text-gray-900">Choose and input your task into the app</h2>
                </div>
              </div>
              <div className="w-full p-4 sm:w-1/2 md:w-1/4">
                <div className="rounded-lg px-4 py-6">
                  <img src="https://res.cloudinary.com/daljbo1q0/image/upload/v1652625194/hdao-nft/17_p1gjpe.png"></img>
                  <h2 className="title-font text-2xl font-medium text-gray-900">Get notified on status changes and completion</h2>
                </div>
              </div>
              <div className="w-full p-4 sm:w-1/2 md:w-1/4">
                <div className="rounded-lg px-4 py-6">
                  <img src="https://res.cloudinary.com/daljbo1q0/image/upload/v1652625194/hdao-nft/16_zehwby.png"></img>
                  <h2 className="title-font text-2xl font-medium text-gray-900">Give feedback so we can improve</h2>
                </div>
              </div>
            </div> */}
            <img hidden className="invisible lg:visible lg:absolute lg:mx-auto" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1652688154/hdao-nft/13_ytevpe.png"></img>
          </div>
        </section>
        <section className="features-2 body-font bg-[#9de0fa] text-gray-600">
          <div className="container mx-auto flex flex-col items-center px-5 py-8 md:flex-row  lg:pb-12 xl:px-12">
            <div className="-mx-4 mt-auto mb-auto flex flex-wrap content-start md:w-full lg:w-1/2">
              <div className="mb-6 w-full px-4 text-center sm:p-4 md:px-8 lg:pl-8">
                <h1 className="mt-12 text-center text-4xl font-bold text-black  md:-mr-16 md:text-3xl lg:px-0 lg:text-left lg:text-4xl">
                  <span style={{ textShadow: "2px 2px white" }} className="desktop hidden font-play uppercase text-black md:block md:text-left">
                    JPEGS WITH REAL UTILITY
                  </span>
                  <span style={{ textShadow: "2px 2px white" }} className="block text-center font-play uppercase text-black md:hidden">
                    JPEGS WITH <br></br>REAL UTILITY
                  </span>
                </h1>
                <p className="mt-6 text-justify text-lg font-normal  text-black md:text-left lg:text-left lg:text-xl">
                  Once activated, the NFT gives actual utility by granting you 20 energy bars to use <span className="font-bold">each week</span> to delegate tasks. 1 energy bar = 30 minutes of PA time, saving you up to 10 hours per week.
                </p>
                <p className="mt-4 text-justify text-lg font-normal text-black md:text-left lg:text-left lg:text-xl">
                  From data entry to online research, Pocket Assistants can take care of it. Below you will find examples of these research or data related tasks. Remember, every time you use Google search you are technically researching something.{" "}
                </p>
              </div>
            </div>
            <div className="mx-auto w-2/4 md:pt-12 lg:w-full lg:max-w-xs">
              <img className="h-full w-full object-contain object-center" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1655789504/hdao-nft/19_yhdazc.png" alt="lightning with clock"></img>
            </div>
          </div>
        </section>
        <section className="activation body-font bg-[#9de0fa] text-gray-600  ">
          <div className="container mx-auto flex flex-col items-center px-5 py-8 lg:flex-row xl:px-12">
            <div className="-mx-4 mt-0 mb-auto flex flex-wrap content-start lg:w-1/2">
              <div className="mb-6 w-full px-4 text-left sm:p-4 md:px-8 lg:pl-8">
                <h1 style={{ textShadow: "2px 2px white" }} className="mt-8  text-center font-play text-4xl uppercase text-black lg:px-0 lg:text-left lg:text-4xl">
                  Activation
                </h1>
                <p className="mt-6 text-justify text-lg font-normal text-black md:text-left lg:text-left lg:text-xl">
                  To activate your NFT and receive the weekly energy, you must stake 10,000 HDAO tokens (currently $150 -{" "}
                  <a className="underline hover:text-blue-800" href="https://app.humandao.org/buyhdao" target="_blank" rel="noreferrer">
                    buy $HDAO here
                  </a>
                  ). As long as HDAO is staked, you can use the energy system to assign tasks every week. Why stake HDAO? Read the FAQs.{" "}
                </p>
              </div>
            </div>
            <div className="mx-auto sm:w-2/3 lg:w-full lg:max-w-md">
              <img className="h-full w-full object-contain object-center" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1652625195/hdao-nft/20_airlpg.png" alt="lightning with clock"></img>
            </div>
          </div>
        </section>
        <section className="features-1 body-font bg-purple-content text-gray-600">
          <div id="assistants" className="container  mx-auto flex flex-col items-center px-5 pt-24 md:flex-row lg:px-5 lg:pt-20">
            <div className="mx-auto max-w-xs md:max-w-5xl lg:ml-4 lg:w-full lg:max-w-md">
              <img className="left-0 h-full w-full object-contain" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1653369892/hdao-nft/18_ta8pvr.png" alt="heart"></img>
            </div>
            <div className="md:2/3 mt-auto mb-auto flex flex-wrap content-start lg:w-2/3">
              <div className="mb-6 w-full px-4 text-left sm:p-4 md:px-8 lg:pl-8">
                <h1 style={{ textShadow: "2px 2px black" }} className="mt-12 text-center font-play text-4xl uppercase text-white-lt md:text-left lg:px-0 lg:text-left lg:text-4xl">
                  Uniquely Human NFTs
                </h1>
                <p className="mt-6 text-justify text-lg font-normal text-white md:text-left lg:text-left lg:text-xl">
                  humanDAO is a social impact project using crypto tools and incentives to benefit the underserved. <span className="font-bold">Pocket Assistants (PA)</span> are qualified humans from marginalized communities who work on these tasks.{" "}
                </p>
                <p className="mt-4 text-justify text-lg font-normal  text-white md:text-left lg:text-left lg:text-xl">
                  Simply holding a PANFT in your wallet allows you to create space in your day-to-day life and unlock your own full potential while changing the lives of people in underserved communities. Help those in need and get your time back.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section class="tasks body-font bg-purple-content text-gray-600">
          <div class="flex w-full flex-col text-center">
            <h1 style={{ textShadow: "2px 2px black" }} class="mx-8 mt-20 font-play text-4xl uppercase  text-white-lt lg:text-5xl">
              Types of Tasks
            </h1>
          </div>
          <div class="container mx-auto px-5 pt-20 pb-16">
            <div class="-m-2 lg:flex lg:flex-wrap">
              <div class="p-6 lg:w-1/2 lg:p-4">
                <div class="relative h-full rounded-2xl bg-purple-box bg-opacity-40 px-8 pt-4 pb-4 text-center">
                  <h1 class="py-4 text-center text-3xl font-bold text-white">Personal Tasks</h1>
                  <ul class="list-disc p-4 text-left">
                    <li class="py-2 text-white">Research flights and hotels</li>
                    <li class="py-2 text-white">Price shop best offers for a product or service</li>
                    <li class="py-2 text-white">Event planning research</li>
                    <li class="py-2 text-white">Confirm appointments</li>
                    <li class="py-2 text-white">Research transportation options</li>
                    <li class="py-2 text-white">Collate and file expenses into a spreadsheet</li>
                  </ul>
                </div>
              </div>
              <div class="p-6 lg:w-1/2 lg:p-4">
                <div class="relative h-full rounded-2xl bg-purple-box bg-opacity-40 px-8 pt-4 pb-4 text-center">
                  <h1 class="py-4 text-center text-3xl font-bold text-white sm:text-3xl">Administration</h1>
                  <ul class="list-disc p-4 text-left">
                    <li class="py-2 text-white">Add accounts to a spreadsheet/CRM</li>
                    <li class="py-2 text-white">Find businesses or peoples emails or contact info</li>
                    <li class="py-2 text-white">Search and find 10 particular images online for use</li>
                    <li class="py-2 text-white">Transcribe audio or notes from a webinar</li>
                    <li class="py-2 text-white">Create/update spreadsheets</li>
                    <li class="py-2 text-white">Verify outdated data, update changes to records</li>
                  </ul>
                </div>
              </div>
              <div class="p-6 lg:w-1/2 lg:p-4">
                <div class="relative h-full rounded-2xl bg-purple-box bg-opacity-40 px-8 pt-4 pb-4 text-center">
                  <h1 class="py-4 text-center text-3xl font-bold text-white">Lead Generation</h1>
                  <ul class="list-disc p-4 text-left">
                    <li class="py-2 text-white">Find leads on social media and/or job board sites</li>
                    <li class="py-2 text-white">Call stores/businesses to get information</li>
                    <li class="py-2 text-white">Retrieve public records/addresses/contact details</li>
                    <li class="py-2 text-white">Find and link me to the blogs for these 10 projects</li>
                    <li class="py-2 text-white">Find and list popular YouTube channels and podcasts on P2E gaming</li>
                    <li class="py-2 text-white">Make a list of keywords to search for a specific product or service</li>
                  </ul>
                </div>
              </div>
              <div class="p-6 lg:w-1/2 lg:p-4">
                <div class="relative h-full rounded-2xl bg-purple-box bg-opacity-40 px-8 pt-4 pb-4 text-center">
                  <h1 class="py-4 text-center text-3xl font-bold text-white">Web3 Research</h1>
                  <ul class="list-disc p-4 text-left">
                    <li class="py-2 text-white">Search and link to the roadmap of these 5 projects</li>
                    <li class="py-2 text-white">Research and collect certain data points over time</li>
                    <li class="py-2 text-white">Find upcoming NFT releases on Solana</li>
                    <li class="py-2 text-white">Find and link me to the blogs for these 10 projects</li>
                    <li class="py-2 text-white">Find and link to Discord servers for these 10 projects</li>
                    <li class="py-2 text-white">Who are the investors (VCs) in xyz company</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="focus body-font bg-purple-content text-gray-600 xl:px-12 ">
          <div className="container mx-auto flex flex-col items-center px-5 py-8 lg:flex-row lg:px-5">
            <div className="mx-auto max-w-xs md:max-w-5xl lg:ml-4 lg:w-full lg:max-w-md">
              <img className="h-full w-full max-w-md object-contain object-center" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1652625195/hdao-nft/21_tdndfg.png" alt="lightning with clock"></img>
            </div>
            <div className="md:2/3 -mx-4 mt-auto mb-auto flex flex-wrap content-start lg:w-2/3">
              <div className="mb-6 w-full px-4 text-left sm:p-4 md:px-8 lg:pl-8">
                <h1 style={{ textShadow: "2px 2px black" }} className="text-center font-play text-4xl uppercase text-white-lt lg:px-0 lg:text-left lg:text-4xl">
                  High-value focus
                </h1>
                <p className="mt-6 text-justify text-lg font-normal  text-white md:text-left lg:text-left lg:text-xl">
                  To spend more time on high-value activities, spend less time on lower-value activities. Make 3 lists that will help you delegate your time more effectively and boost productivity with your PANFT:{" "}
                </p>
                <div className="lg:order-0 order-1 mx-auto w-full px-3">
                  <div className="w-full lg:max-w-md">
                    <ul className="mt-4">
                      <li className="mx-auto inline-flex items-center space-x-4 py-2 lg:text-left xl:py-4">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 flex-row text-white md:h-12 md:w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span className="text-md flex-row text-left font-medium text-white md:text-2xl">Things you hate doing</span>
                      </li>
                      <li className="flex-row items-center space-x-4 py-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="inline-flex h-6 w-6 text-white md:h-12 md:w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span className="text-md inline-flex font-medium text-white md:text-2xl">Things you can't do yourself</span>
                      </li>
                      <li className="inline-flex items-center space-x-4 py-2 xl:py-3">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white md:h-12 md:w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span className="text-md flex-row font-medium text-white md:text-2xl">Things you shouldn't be doing</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="tokenomics" className="range body-font bg-purple-main text-gray-600">
          <div className="flex w-full flex-col text-center">
            <h1 style={{ textShadow: "1px 1px black" }} className="mx-8 mt-20  font-play text-4xl uppercase tracking-wide text-purple-title lg:text-5xl">
              Profound business model
            </h1>
          </div>
          <div className="container mx-auto flex flex-col items-center px-5 py-8 md:flex-row lg:px-5 ">
            <div className="-mx-4 mt-auto mb-auto flex flex-wrap content-start md:w-2/3 lg:w-1/2">
              <div className="mb-6 w-full px-4 text-left sm:p-4 md:px-12 lg:pl-8 lg:text-left">
                <p className="mt-6 mb-6 text-justify text-lg font-normal text-black lg:text-left lg:text-xl">
                  ZipRecruiter, one of the largest recruiting companies, shows the work-from-home Virtual Assistant salary ranges from weekly to monthly in the image. While Pocket Assistants are not full-on personal assistants (yet), the value and
                  business model is still profound.
                </p>
                <div className="mx-auto mt-6 w-5/6 rounded-lg sm:mt-0 sm:w-full md:hidden lg:hidden">
                  <div>
                    <div className="mx-auto w-full p-4 sm:w-3/4">
                      <div className="flex h-full w-full flex-col items-center ">
                        <img className="mb-4 h-full w-full rounded-lg object-center sm:mb-0" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1653754116/hdao-nft/29_hxp7qf.png"></img>
                        <img className="mb-4 h-full w-full  rounded-lg object-center sm:mb-0" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1653754146/hdao-nft/30_nus5ow.png"></img>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="mt-6 mb-6 text-justify text-lg font-normal text-black lg:text-left lg:text-xl">
                  You won't need to pay per month, hour or task with a PANFT. <span className="font-bold">Even on the low end, after a few months, PANFT pays itself off with the one-time mint and activation cost.</span> For minting fees, please see
                  FAQ.
                </p>
              </div>
            </div>
            <div className="mt-6 hidden w-full rounded-lg sm:mt-0 md:block md:w-1/2 lg:ml-8 lg:block lg:w-2/5">
              <div>
                <div className="w-full p-4">
                  <div className="flex h-full w-full flex-col items-center">
                    <img className="mb-4 h-full w-full rounded-lg object-center sm:mb-0" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1653754116/hdao-nft/29_hxp7qf.png"></img>
                    <img className="mb-4 h-full w-full  rounded-lg object-center sm:mb-0" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1653754146/hdao-nft/30_nus5ow.png"></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="tokenomics body-font bg-purple-main pb-32">
          <div className="container mx-auto px-5 pt-16 lg:pb-0">
            <div className="mb-20 flex w-full flex-col text-center">
              <h1 style={{ textShadow: "1px 1px black" }} className="mx-4 mt-20 font-play text-4xl uppercase text-purple-title lg:text-5xl">
                Best in-class tokenomics
              </h1>
              <p className="mx-auto mt-4 text-base leading-relaxed sm:text-xl lg:w-2/3">(fees from PANFT mints, rents, sales)</p>
            </div>
            <div>
              <img className="mx-auto w-full sm:w-3/4 md:-mb-20 lg:w-2/4" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1654783084/hdao-nft/tokenomics_rf0h4f.png"></img>
            </div>
          </div>
        </section>
        <section className="benefits body-font bg-purple-main text-gray-600">
          <div className="container mx-auto px-5 py-12">
            <div className="flex w-full flex-col text-center xl:px-24">
              <h1 style={{ textShadow: "1px 1px black" }} className="mt-20 mb-12 font-play text-3xl uppercase tracking-wide text-purple-title sm:text-4xl lg:mx-2 lg:text-5xl">
                Designed to be the most functional asset in your portfolio
              </h1>
            </div>
            <div className="-m-4 flex flex-wrap">
              <div className="md:1/2 p-4 lg:w-1/3">
                <div className="flex h-full flex-col rounded-lg p-8">
                  <div className="mx-auto mb-3 flex items-center">
                    <img className="sm:1/2 mx-auto w-full md:w-1/2 lg:w-full" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1652625196/hdao-nft/27_baciv3.png"></img>
                  </div>
                  <div className="flex-grow">
                    <p className=" text-2xl font-semibold text-black">Take charge of your time</p>
                    <p className="px-8 text-center text-lg font-normal leading-tight text-black sm:px-28 lg:px-0">Do more of what you love by delegating low-value tasks, all at the touch of a button.</p>
                  </div>
                </div>
              </div>
              <div className="md:1/2 p-4 lg:w-1/3">
                <div className="flex h-full flex-col rounded-lg p-8">
                  <div className="mx-auto mb-3 flex items-center">
                    <img className="sm:1/2 mx-auto w-full md:w-1/2 lg:w-full" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1655406557/hdao-nft/8_mcgeyh.png"></img>
                  </div>
                  <div className="flex-grow">
                    <p className=" text-2xl font-semibold text-black">One upfront cost</p>
                    <p className="px-8 text-center text-lg font-normal leading-tight text-black sm:px-28 lg:px-0">Forget paying yet another subscription. Your NFT is yours to use for as long as you need it.</p>
                  </div>
                </div>
              </div>
              <div className="md:1/2 p-4 lg:w-1/3">
                <div className="flex h-full flex-col rounded-lg p-8">
                  <div className="mx-auto mb-3 flex items-center">
                    <img className="sm:1/2 mx-auto w-full md:w-1/2 lg:w-full" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1652625196/hdao-nft/25_i3xk7z.png"></img>
                  </div>
                  <div className="flex-grow">
                    <p className="text-2xl font-semibold text-black">Earn fees</p>
                    <p className="px-4 text-center text-lg font-normal leading-tight text-black sm:px-28 lg:px-0">Even if you don't use the PA service, just hodling PANFT allows you to receive fees from all mints, rents, and sales</p>
                  </div>
                </div>
              </div>
              <div className="md:1/2 p-4 lg:w-1/3">
                <div className="flex h-full flex-col rounded-lg p-8">
                  <div className="mx-auto mb-3 flex items-center">
                    <img className="sm:1/2 mx-auto w-full md:w-1/2 lg:w-full" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1652625195/hdao-nft/23_gcjkfw.png"></img>
                  </div>
                  <div className="flex-grow">
                    <p className=" text-2xl font-semibold text-black">Fully transferrable</p>
                    <p className="px-8 text-center text-lg font-normal leading-tight text-black sm:px-28 lg:px-0">
                      Stay agile when things change. Loan your NFT in quiet times to earn fees from other users, or sell it on the open market. You're always in control.
                    </p>
                  </div>
                </div>
              </div>
              <div className="md:1/2 p-4 lg:w-1/3">
                <div className="flex h-full flex-col rounded-lg p-8">
                  <div className="mx-auto mb-3 flex items-center">
                    <img className="sm:1/2 mx-auto w-full md:w-1/2 lg:w-full" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1652625196/hdao-nft/26_gnscxi.png"></img>
                  </div>
                  <div className="flex-grow">
                    <p className=" text-2xl font-semibold text-black">Share energy</p>
                    <p className="px-8 text-center text-lg font-normal leading-tight text-black sm:px-28 lg:px-0">
                      Give the gift of time to friends, family, and colleagues, or split the cost and share the benefits. Your PANFT is as flexible as it is useful.
                    </p>
                  </div>
                </div>
              </div>
              <div className="md:1/2 p-4 lg:w-1/3">
                <div className="flex h-full flex-col rounded-lg p-8">
                  <div className="mx-auto mb-3 flex items-center">
                    <img className="sm:1/2 mx-auto w-full md:w-1/2 lg:w-full" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1652625195/hdao-nft/22_rekqjr.png"></img>
                  </div>
                  <div className="flex-grow">
                    <p className="text-2xl font-semibold text-black">Support & empower people in need</p>
                    <p className="px-4 text-center text-lg font-normal leading-tight text-black sm:px-28 lg:px-0">
                      Assistants earn more than a competitive wage. Additional token incentives distribute ownership of the DAO for a brighter financial future.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="form body-font bg-purple-main text-gray-600">
          <div class="container mx-auto flex flex-col items-center p-12 px-4 pb-28 lg:flex-row">
            <div className="mx-auto w-full md:mt-12 md:w-2/3 md:p-2 md:pt-8 lg:mt-0 lg:w-full lg:max-w-lg">
              <Slider {...settings}>
                <div className="mx-auto w-5/6 animate-fade-in-down ease-in md:w-2/3 md:p-2 lg:mt-2 lg:w-full">
                  <img className="w-full object-contain object-center" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1653316999/hdao-nft/28_i46bhc.png" alt="commmunity"></img>
                </div>
                <div className="mx-auto w-5/6 animate-fade-in-down md:mt-24 md:w-2/3 md:p-2 lg:mt-0">
                  <img className="w-full object-contain object-center" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1655790693/hdao-nft/1_fdqd59_wi3bgk.png" alt="person arranging blocks"></img>
                </div>
                <div className="mx-auto w-5/6 animate-fade-in-down md:w-full md:p-2 lg:mt-0">
                  <img className="w-full object-contain object-center" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1655401277/hdao-nft/39_fiahck.png" alt="two people using phone"></img>
                </div>
              </Slider>{" "}
            </div>
            <div class="mx-auto items-center text-center md:items-start md:text-left lg:flex-grow lg:pl-24">
              <h1 style={{ textShadow: "2px 3px white" }} class="mb-4 font-play text-4xl uppercase text-[#dd5b87] lg:text-5xl">
                Ready to get more done?
              </h1>
              <div class="mx-auto flex flex-col sm:text-left md:mx-0 lg:flex-row ">
                <a class="inline-flex items-center rounded-lg bg-purple-box px-4 py-4 font-bold text-white hover:bg-purple-box/80 focus:outline-none" target="_blank" rel="noreferrer" href="https://forms.gle/KrtKcNjXYtcUX6Sh7">
                  <span className="mx-auto">Join waitlist</span>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="faqs" className="ambassador body-font bg-[#a3b7ff] text-gray-600">
          <div className="pb-7 pt-12 lg:pb-4 lg:pt-16">
            <h2 style={{ textShadow: "1px 1px white" }} className="bar title-font mx-auto mb-5 py-1 pt-4 text-center font-play text-2xl uppercase tracking-tight text-black sm:text-2xl md:px-2 md:text-3xl lg:px-24 tabletlandscape:text-2xl">
              HELP THOSE IN NEED AND EARN? <br></br> YES PLEASE!
            </h2>
            <h2 style={{ textShadow: "1px 1px white" }} className="bar title-font mx-auto mb-7 px-5 text-center font-play text-lg text-black sm:text-xl md:px-2 lg:mb-4 lg:px-40 lg:text-xl">
              We are looking for an artist to design this innovative NFT. As well as influencers or web3 communities to help gain awareness for this project. If this is you, join our Ambassador program{" "}
            </h2>
            <a className="mx-auto items-center rounded-lg bg-purple-box/80 py-3 px-5 font-bold text-white hover:bg-purple-box/80 focus:outline-none lg:inline-flex" target="_blank" rel="noreferrer" href="https://forms.gle/fuv8zGjvxLKzaDjV6">
              Ambassadors
            </a>
          </div>
        </section>
        <section className="FAQs relative bg-[#a3b7ff] pt-24 pb-12">
          <div className="container mx-auto px-8 xl:px-5">
            <h1 style={{ textShadow: "1px 1px white" }} className="mx-2 mt-12 font-play text-4xl uppercase tracking-wide text-black lg:text-5xl">
              Frequently Asked Questions
            </h1>
          </div>
          <div className="mx-auto w-full py-6 pt-4 text-center text-xs sm:px-8 md:px-12 lg:w-5/6 xl:w-2/3">
            <Accordion>
              <div className="duration-300 ease-in" label="Is this a personal assistant?">
                <p>No. All tasks go to a pool of available PAs. You will have many PAs fulfilling your acceptable tasks.</p>
              </div>
              <div label="What are acceptable tasks?">
                <p>
                  As the examples above show, pretty much anything beginning with Find, Collect, Make, Add, or Search. You will not have the same PA for all your tasks. Thus, anything requiring privileged info (email, Amazon, bank login) will not
                  work. Stick to research and data entry, and you will be fine. We are looking to expand acceptable tasks in the future.
                </p>
              </div>
              <div label="Where do these PAs come from?">
                <p>Our community comes from low economic backgrounds but are connected to the internet, ambitious and hungry for opportunity to earn. Being a PA is like being an Uber driver, in that it is gig work.</p>
                <p className="pt-4">
                  Join our community ​
                  <span>
                    <a className="underline hover:text-blue-400" href="https://discord.com/invite/humandao" target="_blank" rel="noreferrer">
                      here
                    </a>
                  </span>
                  .{" "}
                </p>
                <p className="pt-4">
                  Learn about us ​
                  <span className="underline hover:text-blue-400">
                    <a href="https://docs.humandao.org" target="_blank" rel="noreferrer">
                      here
                    </a>
                  </span>
                  .
                </p>
              </div>
              <div label="What will it cost to mint?">
                <p>We are deciding what kind of launch it will be (whitelist, batch auction, etc.) Regardless, we need to set a floor value for the mint due to the utility provided (different from PFPs). The floor price will be $3,333.</p>
                <p className="pt-4">
                  Threes represent the parties involved—the holder, DAO, and PA. Additionally, we will be lowering the floor price only once for the 1st/initial mint and the following mints will be limited.{" "}
                  <a className="underline hover:text-blue-400" href="https://forms.gle/D96hyhNDh9DDJAXa8" target="_blank" rel="noreferrer">
                    So join the waitlist.
                  </a>
                </p>
              </div>
              <div label="How many NFTS will be available?">
                <p>
                  PANFT will be very limited. We are expecting to have 50-500 available for launch (1st mint), depending on demand. From there we will test and improve the application and service for a few weeks. Then, we will be releasing limited
                  weekly mints thereafter.{" "}
                  <a className="underline hover:text-blue-400" href="https://forms.gle/D96hyhNDh9DDJAXa8" target="_blank" rel="noreferrer">
                    Join the waitlist.
                  </a>
                </p>
              </div>
              <div label="What are the features and benefits of the NFT?">
                <p className="italic">Tokenomics: fees from PANFT mints, rents, sales distributions:</p>
                <ul className="mx-auto list-disc pt-6 pl-4">
                  <li>40% PA pool (gig workers)</li>
                  <li>40% hDAO treasury</li>
                  <li>10% PANFT token holders</li>
                  <li>10% HDAO buyback</li>
                </ul>
                <p className="pt-6">That's right, PANFT holders will collect a portion of the fees 💪</p>
                <p className="pt-6 italic">Additional PANFT features:</p>
                <ul className="mx-auto list-disc pt-6 pl-4">
                  <li>Extremely limited</li>
                  <li>CC0 for design and IP (more to come on this)</li>
                  <li>Shareable. Whether an individual or an organisation, you can share the weekly energy with your family or employees.</li>
                </ul>
              </div>
              <div label="How is this different from Fiverr?">
                <p className="italic">From the buyer's perspective</p>
                <ul className="mx-auto list-disc pt-6 pl-4">
                  <li>Don’t have to spend time finding the right person every time</li>
                  <li>
                    Don’t have to pay per task/job, <span className="font-bold">no recurring cost</span>
                  </li>
                  <li>Can resell because NFT</li>
                  <li>Can make money through the rental market</li>
                  <li>Complete privacy, only validates holder</li>
                  <li>Can share your access to the service</li>
                </ul>
                <p className="pt-6 italic">From the worker's perspective</p>
                <ul className="mx-auto list-disc pt-6 pl-4">
                  <li>No need to undercut prices or spend time gaining customers/work by building up reviews</li>
                  <li>More like Uber than Fiverr, login, and tasks are waiting</li>
                  <li>20% get 80% of jobs on Fiverr. Earlier adopters benefit/dominate, not so with PANFT</li>
                </ul>
              </div>
              <div label="Can I roll over my energy?">
                <p>Energy not used does NOT roll over to the following week. But your 20 energies will reload every week (as long as HDAO is staked).</p>
              </div>
              <div label="Why do we have to stake HDAO?">
                <p>Activating the NFT by staking HDAO helps us forecast demand for the PAs and ensure enough resources are available to complete tasks quickly.</p>
                <p className="pt-4">Holding and using HDAO benefits the underserved communities making up the humanDAO, grants staking rewards (get more $HDAO), and you can participate in governance as a true DAO member. </p>
                <p className="pt-4 underline hover:text-blue-400">
                  <a href="https://app.humandao.org/buyhdao" target="_blank" rel="noreferrer">
                    Buy HDAO here
                  </a>
                </p>
                <p className="pt-4 underline hover:text-blue-400">
                  <a href="https://app.humandao.org/dashboard" target="_blank" rel="noreferrer">
                    Visit our Dashboard for DAO metrics
                  </a>
                </p>
              </div>
              <div label="Why a 30% royalty on secondary sales?">
                <p>
                  Unlike artistic NFTs and PFP projects, PANFT is a utility token actively managed by the DAO. Secondary sales royalties contribute to these ongoing costs and the sustainability of the DAO, enabling us to build more services and
                  products to benefit underserved communities and achieve our goal of improving lives through crypto.
                </p>
              </div>
              <div label="What is the rental market?">
                <p>
                  We plan on creating a rental market for energy. PANFT holders will be able to loan out weekly energy if/when they are unable to use it themselves, earning a fee. It’s a great way to make a few dollars and make the most of your
                  purchase.
                </p>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    );
  }
}

export default Content;
