import React from "react";
import PropTypes from "prop-types";

class AccordionSection extends React.Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const {
      onClick,
      props: { isOpen, label },
    } = this;

    return (
      <div
        className="text-xl font-sans text-white font-semibold px-2 py-4  md:px-8 md:py-4 m-10 text-left"
        style={{
          background: isOpen ? "#e8e2ff" : "#e8e2ff",
          border: "1px solid #7b66d4",
          background: "#543eb6",
          color: "white",
          fontWeight: "bold",
          borderRadius: "5px",
        }}
      >
        <div className="float-right">
          {!isOpen && (
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
              </svg>
            </span>
          )}
          {isOpen && (
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
              </svg>
            </span>
          )}
        </div>
        <div onClick={onClick} style={{ cursor: "pointer" }}>
          {label}
        </div>
        {isOpen && (
          <div
            className="text-xl font-sans text-white font-normal"
            style={{
              marginTop: 10,
              padding: "10px 20px",
              background: "#543eb6",
            }}
          >
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

export default AccordionSection;
